import React, { useEffect, useState } from "react";
import { BiUser } from "react-icons/bi";
import { MdOutlineLocationOn, MdOutlineModeEditOutline } from "react-icons/md";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDrawer from "../../Components/global-components/CommonDrawer/CommonDrawer";
// import AddressInput from "./AddressInput";
import { useSelector, useDispatch } from "react-redux";
import Tab from "../../Components/kyc-components/Tab";
import CommonDropDown from "../../Components/global-components/CommonDropDown/CommonDropDown";
import TextInput from "../../Components/global-components/CommonTextInput/TextInput";
// import { TextareaAutosize } from "@mui/material";
import { customerData } from "../Redux/userData";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { activeStepper } from "../Redux/stepperSlice";
import {
  documentDetail,
  fileUpload,
  previewIamgeURL,
} from "../Redux/otherDocsSlice";
import { toggleTabs } from "../Redux/slice";
import { formValidator, gaFunction, OEM_List } from "../../Router";
import { TextField } from "@mui/material";
import ErrorBottomDrawer from "../../Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer";
import { showGlobalError } from "../Redux/globalErrorSlice";
import { getImage } from "../../services/camera-service";
import { decrypt, encrypt } from "../../utils/encryptionV2";
import axios from "axios";
const gender = ["Gender", "Male", "Female", "Others"];
const maritalStatus = ["Marital Status", "Single", "Married"];
const travelOptions = ["Travel", "India", "Abroad"];

const TrOtherDocsFetchDetailsFrame = () => {
  const {
    name,
    address,
    reduxGenderValue,
    dob: dobRedux,
    fatherName: fatherNameRedux,
    permanentAddress,
    permanentCity,
    permanentPincode,
    permanentState,
    passportNumber: passportNumberRedux
  } = useSelector((state) => state.uploadedFile.previewDocumentDetail);
  const userImage = useSelector((state) => state.CameraImage.userImage);
  const switchGendervalue = (reduxGenderValue) => {
    switch (reduxGenderValue) {
      case "M":
        return "Male";

      case "F":
        return "Female";

      case "O":
        return "Others";

      case "T":
        return "Other";

      default:
        return "";
    }
  };

  const frontFileName = useSelector(
    (state) => state.uploadedFile.documentImage.frontFile
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [verifyDetailsMsgPopup, setVerifyDetailsMsgPopup] = useState(false);
  const [addressActive, setAddressActive] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState(true);
  const [motherName, setMotherName] = useState("");
  const [error, setError] = useState("");
  const [coError, setCoError] = useState("");
  // const [isEditable, setIsEditable] = useState(false);
  // const [nameEditable, setNameEditable] = useState(true);
  const [coAddress, setCoAddress] = useState("");
  const [coState, setCoState] = useState("");
  const [coCity, setCoCity] = useState("");
  const [coPinCode, setCoPinCode] = useState("");
  const [paAddress, setPaAddress] = useState(address || "");
  const [paState, setPaState] = useState(permanentState || "");
  const [paCity, setPaCity] = useState(permanentCity || "");
  const [paPinCode, setPaPinCode] = useState(permanentPincode || "");
  const [genderValue, setGenderValue] = useState(
    switchGendervalue(reduxGenderValue) || ""
  );
  const [maritalValue, setMaritalValue] = useState("");
  const [IsCheckbox, setIsCheckbox] = useState(false);
  const [localAddress, setLocalAddress] = useState(address);
  const [dob, setDob] = useState(dobRedux === "Invalid Date" || dobRedux === ""   ? "" : changedateformatRedux(dobRedux));
  const [localName, setLocalName] = useState(name);
  const [fatherName, setfatherName] = useState(
    !fatherNameRedux ? "" : fatherNameRedux
  );
  const [travelValue, setTravelValue] = useState("Abroad");
  const [passportNumber, setPassportNumber] = useState(passportNumberRedux || "");
  const [passportError, setPassportError] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isRouteAllowed } = state || {};
  const dispatch = useDispatch();
  const { channel } = useSelector(
    (state) => state?.partnerChannelNameSlice?.travelUserDetails
  );
  const selectedDoctype = useSelector(
    (state) => state.dropDownValue.selectedValue
  );
  const { mi_u, channelUrl,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const kycRefId = useSelector((state) => state.uploadedFile.kycRefId);
  const isSelfieUrlChange = useSelector(
    (state) => state.CameraImage.selfieChangeUrl
  );
  const digitalFormSixtyID= useSelector((state) => state.digitalFormSixty.formSixtyData.digitalForm60Id);
  const userPanNumber = useSelector((state) => state.panDetails.userPanNumber);
  const kycRefIdAddNewKyc = useSelector((state) => state.kycDetails.kycRefIdAddNewKyc);

  const customerData2 = useSelector((state) => state.userDetails.customerData);

  const AddressHandler = () => {
    setAddressActive(!addressActive);
    setCheckboxActive(!checkboxActive);
  };
  //   const panUserDetails = useSelector((state) => state.panDetails.panUserdata);

  let fieldArray = [
    { fieldLength: motherName?.trim()?.length, fieldName: "mother name" },
    { fieldLength: localName?.trim()?.length, fieldName: "Name" },
    { fieldLength: maritalValue?.trim()?.length, fieldName: "marital status" },
    { fieldLength: genderValue?.trim()?.length, fieldName: "gender" },
    { fieldLength: dob?.length, fieldName: "Date of Birth" },
    { fieldLength: paAddress?.trim()?.length, fieldName: "Address" },
    { fieldLength: fatherName?.trim()?.length, fieldName: "Father Name" },
    { fieldLength: paState?.trim()?.length, fieldName: "State" },
    { fieldLength: paCity?.trim()?.length, fieldName: "City" },
    { fieldLength: paPinCode?.trim()?.length, fieldName: "Pincode" },
  ];
  let fieldArrayTravel = [
    // { fieldLength: motherName?.trim()?.length, fieldName: "mother name" },
    { fieldLength: localName?.trim()?.length, fieldName: "Name" },
    // { fieldLength: maritalValue?.trim()?.length, fieldName: "marital status" },
    // { fieldLength: genderValue?.trim()?.length, fieldName: "gender" },
    { fieldLength: dob?.length, fieldName: "Date of Birth" },
    { fieldLength: paAddress?.trim()?.length, fieldName: "Address" },
    // { fieldLength: fatherName?.trim()?.length, fieldName: "Father Name" },
    { fieldLength: paState?.trim()?.length, fieldName: "State" },
    { fieldLength: paCity?.trim()?.length, fieldName: "City" },
    { fieldLength: paPinCode?.trim()?.length, fieldName: "Pincode" },
  ];
  let correspondenceFieldArray = [
    {
      fieldLength: coAddress?.trim()?.length,
      fieldName: "Correspondence Address",
    },
    { fieldLength: coState?.trim()?.length, fieldName: "Correspondence State" },
    { fieldLength: coCity?.trim()?.length, fieldName: "Correspondence City" },
    {
      fieldLength: coPinCode?.trim()?.length,
      fieldName: "Correspondence Pincode",
    },
  ];
  const pinCodeValidator = (value) => {
    let pinCodeRegex = /^[0-9\b]+$/;
    if (pinCodeRegex.test(value)) {
      setCoPinCode(value);
    }
    if (!pinCodeRegex.test(value)) {
      setCoPinCode("");
    }
  };
  const pApinCodeValidator = (value) => {
    let pinCodeRegex = /^[0-9\b]+$/;
    if (pinCodeRegex.test(value)) {
      setPaPinCode(value);
    }
    if (!pinCodeRegex.test(value)) {
      setPaPinCode("");
    }
  };

  //todo : remove comment after testing
  useEffect(() => {
    setTimeout(() => {
      setOpenDrawer(true);
    }, 1000);
  }, []);

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const yesHandler = () => {
    setOpenDrawer(false);
    // setVerifyDetailsMsgPopup(true);
  };

  const noHandler = () => {
    navigate("/kyc");
    dispatch(fileUpload({ backFile: "", frontFile: "" }));
    dispatch(previewIamgeURL({ backImageURL: "", frontImageURL: "" }));

    // dispatch(toggleTabs("otherDocs"));
  };

  const nameEditingHandler = (e) => {
    setLocalName(e.target.value);
  };

  // const backHandler = () => {
  //   navigate("/kyc");
  // };
  //webcam
  const checkBoxHandler = () => {
    setIsCheckbox((prev) => !prev);
  };
  function changedateformat(val) {
    const myArray = val.split("-");
    let year = myArray[0];
    let month = myArray[1];
    let day = myArray[2];
    let formatteddate = day + "-" + month + "-" + year;
    return formatteddate;
  }
  function changedateformatRedux(val) {
    const myArray = val.split("/");
    let year = myArray[2];
    let month = myArray[1];
    let day = myArray[0];
    let formatteddate = year + "-" + month + "-" + day;
    return formatteddate;
  }
  const continueHandler = () => {
    const emptyFieldName = formValidator(channel === "travel" ? fieldArrayTravel : fieldArray);
    const coEmptyFieldName = formValidator(correspondenceFieldArray);
    console.log("emptyFieldName", emptyFieldName)
    if (emptyFieldName) {
      setError("Please provide all details : " + emptyFieldName);
      dispatch(showGlobalError("Please fill this detail : " + emptyFieldName));
      return;
    }
    if (!checkboxActive && coEmptyFieldName) {
      setCoError("Please fill this detail : " + coEmptyFieldName);
      dispatch(
        showGlobalError("Please fill this detail : " + coEmptyFieldName)
      );
      return;
    }
    if(channel === "travel" && !travelValue){
      return dispatch(
        showGlobalError("Please fill this detail : Travel")
      );
    }
    if(channel === "travel" && travelValue ==="Abroad" && (!passportNumber || passportError)){
      return dispatch(
        showGlobalError("Please fill this detail : Passport Number")
      );
    }

    gaFunction("OVD", "OVD Continue Clicked");
    let isInIf = false;

    if (
      channel === "travel" &&
      dob !== "Invalid Date" &&
      dob !== ""
    ) {
      console.log("in travel continue")
      isInIf = true;
    //   navigate(userImage ? "/image" : "/webcam", {
    //     state: {
    //       isRouteAllowed: true,
    //       type: "ovd",
    //     },
    //   });
      dispatch(
        customerData({
          gender: genderValue,
          maritalStatus: maritalValue,
          mothersName: motherName,
          correspondenceAddress: coAddress,
          correspondenceState: coState,
          correspondenceCity: coCity,
          correspondencePincode: coPinCode,
          permanentAddress: paAddress,
          permanentState: paState,
          permanentCity: paCity,
          permanentPincode: paPinCode,
          userEditedAddress:
            paAddress !== address ||
            paCity !== permanentCity ||
            paState !== permanentState ||
            paPinCode !== permanentPincode,
          userEditedName: name !== localName,
          name: name === localName ? name : localName,
          manualKYC:fatherNameRedux!==fatherName || dobRedux === "Invalid Date" || dobRedux === "" || changedateformatRedux(dobRedux)!==dob || paPinCode !== permanentPincode ,
          fatherName,
          travelCountry: travelValue,
          passportNumber,
        })
      );
      dispatch(activeStepper(2));
        dispatch(
          documentDetail({
            dob: changedateformat(dob),
            fatherName,
          })
        );

      submitHandler();

    } else {
      setError("Please provide all details");
    }
    if (!isInIf) {
      alert("Please fill all fields");
    }
  };
  // const editHandler = () => {
  //   setIsEditable((prev) => !prev);
  // };
  // const nameEditHandler = () => {
  //   setNameEditable((prev) => !prev);
  // };

  const skipDueDeligence = () => {
    let data = JSON.stringify({
      // mobile: phoneNumber,
      pan: userPanNumber,
      // annualIncome: annualIncome,
      // occupation:
      //   occupation === "Other"
      //     ? `${occupation} - ${occupationOther}`
      //     : occupation,
      // organizationType:
      //   organization === "Other"
      //     ? `${organization} - ${organizationOther}`
      //     : organization,
      // isPoliticallyExposed:
      //   political === "Yes" || political === "Related to one"
      //     ? true
      //     : false,
      gender: genderValue,
      maritalStatus: maritalValue,
      mothersName: motherName,
      correspondenceAddress: coAddress,
      correspondenceState: coState,
      correspondenceCity: coCity,
      correspondencePincode: coPinCode,
      type: selectedDoctype === "maskedAadhaar" ? "aadhaar" : channel === "travel" ? "passport" : selectedDoctype,
      permanentCity: paCity,
      permanentDistrict: customerData2?.permanentDistrict,
      permanentCountry: customerData2?.permanentCountry,
      permanentState: paState,
      permanentPincode: paPinCode,
      permanentAddress: paAddress,
      name: name === localName ? name : localName,
      // mi_identifier: mi_u,
      channel: channel === "travel" ? "travel" : channelUrl,
      subChannel,
      txnId: channel === "travel" ? localStorage.getItem("txnId") : mi_u,
      kycId: kycRefIdAddNewKyc,
      dob: dob,
      fatherName: customerData2?.fatherName || fatherName || "",
      userEditedName: customerData2?.userEditedName,
      userEditedAddress: 
        paAddress !== address ||
        paCity !== permanentCity ||
        paState !== permanentState ||
        paPinCode !== permanentPincode,
      manualKYC: customerData2?.manualKYC,
      digitalFormSixtyID: digitalFormSixtyID || "",
      passportNumber: channel === "travel" ? passportNumber : "",
      status: channel === "travel" && "travelApproved"
    });
    // https://ctrln-uat.hdfcergo.com
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}/customer/customer-kyc`,
      headers: {
        "Content-Type": "application/json",
        txnId: localStorage.getItem("txnId") === "null" ?? null,
        // token: localStorage.getItem("token"),
      },
      // data: data,
      data: {enc_request: encrypt(data)},
    };

    axios(config)
      // .then(function (response) {
      //   if (responsedecResponsesuccess === true) {
      //     dispatch(activeStepper(3));
      //     navigate("/Success", {
      //       state: {
      //         kyc_ref_id: response?.data?.data?.kyc_id,
      //         successUrl: response?.data?.data?.success_url,
      //         typeOfKyc: "ovd",
      //       },
      //     });
      //   }
      // })
      .then(function (response) {
        let decResponse= decrypt(response?.data?.enc_response)
        console.log("IMAGE SCREEN FETCH DETAIL RESPONSE --",decResponse);
        if (decResponse.success === true) {
          dispatch(activeStepper(3));
          navigate("/Success", {
            state: {
              kyc_ref_id: decResponse.data?.kyc_id,
              successUrl: decResponse.data?.success_url,
              typeOfKyc: "ovd",
            },
          });
        }
      })
      .catch(function (error) {
        console.log("IMAGE SCREEN FETCH DETAIL FAILED --",error);
      });
  };
  //   let fileBase64Array = [userImage];
// MNAIN FUNCTION
let convertedFiles = async () => {
  console.log("IMAGESCREEN -------- IMAGE" ,  userImage)
  let frontImageConversionToFile = null;
  let file = null;
  await fetch(userImage, {mode: 'no-cors'})
    .then((res) => res.blob())
    .then((blob) => {
      frontImageConversionToFile = blob;
      let blobToFileFront = new File([blob], frontFileName, {
        type: frontImageConversionToFile.type,
        // type: "image/jpeg",
      });
      file = blobToFileFront;
    }).catch(err=>console.log("catch err", err));
    console.log("IMAGESCREEN -------- RESULT" ,  file)
  return file;
  // return userImage;
};

  const submitHandler = () => {
    gaFunction("Selfie", "Selfie Submitted");
    console.log("isSelfieUrlChange",isSelfieUrlChange)
    // setIsLoading((prev) => !prev);
    if (!isSelfieUrlChange) {
      if (OEM_List.includes(channelUrl) || OEM_List.includes(channel)) {
        skipDueDeligence();
      } else {
        console.log(" ELSE ------ 175")
        navigate("/income", {
          state: {
            isRouteAllowed: true,
            type:
              selectedDoctype === "maskedAadhaar" ? "aadhaar" : selectedDoctype,
            typeOfDoc: "ovd",
          },
        });
      }
    } else {
      console.log("ELSE ----- 185")
      skipDueDeligence();
    //   let FormData = require("form-data");
    //   let data = new FormData();
    //   convertedFiles().then((file) => {
    //     data.append("front", file);
    //     data.append("kycRefId", kycRefId);
    //     getImage(data)
    //       .then(function (response) {
    //         console.log("response", response)
    //         if (response.success === true) {
    //           dispatch(activeStepper(2));
    //         //   setIsLoading((prev) => !prev);
    //           if (OEM_List.includes(channelUrl) || OEM_List.includes(channel)) {
    //             skipDueDeligence();
    //           } else {
    //             navigate("/income", {
    //               state: {
    //                 kyc_ref_id: response?.data?.kycRefId,
    //                 isRouteAllowed: true,
    //                 type:
    //                   selectedDoctype === "maskedAadhaar" ? "aadhaar" : selectedDoctype,
    //                 typeOfDoc: "ovd",
    //               },
    //             });
    //           }
    //           // navigate("/success", {
    //           //   state: { kyc_ref_id: response?.data?.kycRefId },
    //           // });
    //         }
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         // setIsLoading(false);
    //       });
    //   });
    }
  };

  const handlePassportChange= (e) => {
    const value = e.target.value.toUpperCase();
    setPassportNumber(value);
    const validatePassport = (/^[A-Z]{1,2}[0-9]{6,7}$/).test(value);
    if(!validatePassport){
      setPassportError("Invalid passport number");
    } else {
      setPassportError("");
    }
  }

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);

  useEffect(() => {
    if (!state) navigate("/");
  }, [navigate, state]);

//   useEffect(() => {
//     if (!frontFileName?.length) navigate("/kyc");
//   }, [frontFileName?.length]);

  return (
    <>
      {isRouteAllowed && (
        <div className="flex flex-col h-full">
          <MsHome>
            <BannerImage>
              {/* <CommonTopBackButton backHandler={backHandler} /> */}
              <LogoContainer />
            </BannerImage>

            <div className="bg-[#fce9e9] h-full">
              <BottomCard>
                <div className="h-max w-full z-0 last:mb-[15vh] md:mb-0 md:h-full">
                  {/* <div className="md:flex justify-around"> */}

                 {/* <div className="hidden md:block md:h-[30%]">
                    <div className="p-[10px] md:p-0"></div>
                    <label className="md:text-xl">
                      <b>KYC Document</b>
                    </label>
                    <p className="hidden md:block md:text-sm">
                      let's fetch your kyc details using
                    </p>
                    <div className="p-[10px] md:p-[7px]"></div>
                    <div className="md:w-[50%] md:mb-5">
                      <Tab isDisabled ={false}/>
                    </div>
                  </div> */}
                  {/* <div className="md:w-[70%] md:h-[52%] md:block overflow-scroll  md:p-0"> */}
                  <div className="md:w-[99%] md:h-[80%] md:block overflow-scroll  md:p-0">
                    <label>
                      <b className="required">Name</b>
                    </label>
                    <div className="flex  p-2  w-full">
                      <div className="px-4 flex items-center">
                        <BiUser size={25} />
                      </div>
                      <div className="w-full">
                        <TextInput
                          editIcon={true}
                          value={localName}
                          onChange={nameEditingHandler}
                          width="md:w-[100%]"
                          showInput={false}
                        />
                      </div>
                    </div>

                    {/* Travel details  */}
                    {channel === "travel" && 
                      <div>
                        {/* <CommonDropDown
                          options={travelOptions}
                          selected={travelValue}
                          label="Travel"
                          setSelected={setTravelValue}
                        /> */}
                        {travelValue === "Abroad" && 
                        <div className="mb-2">
                          <label>
                            <b className="required">Passport Number</b>
                          </label>
                          <TextInput
                            placeholder="Passport number"
                            value={passportNumber}
                            onChange={handlePassportChange}
                            width="md:w-[291px]"
                            maxlen="8"
                          />
                          {passportError && <p className="text-red-500 text-sm">{passportError}</p> }
                        </div>
                        }
                      </div>
                    }

                    <label>
                      <b className="required">Permanent Address</b>
                    </label>
                    <div className="flex flex-row  p-2">
                      <div className="flex flex-col px-4">
                        <MdOutlineLocationOn size={25} />
                      </div>
                      <div className="flex justify-between gap-2 w-full">
                        {/* <textarea
                          // cols={30}
                          className="min-h-max min-w-max resize-none"
                          onChange={(e) => setLocalAddress(e.target.value)}
                        >
                          {address || "NA"}
                        </textarea> */}
                        <div className="main_address_container w-full">
                          <div>
                            <TextInput
                              label={"Address"}
                              editIcon
                              id="pa_address"
                              placeholder={"ex. House no, Flat no, ABC apt"}
                              onChange={(e) => setPaAddress(e.target.value)}
                              value={paAddress}
                              showInput={false}
                            />
                          </div>
                          <div className="md:flex md:flex-row block justify-between items-center gap-5">
                            <TextInput
                              label={"State"}
                              editIcon
                              id="pa_state"
                              onChange={(e) => setPaState(e.target.value)}
                              value={paState}
                              placeholder="ex. Maharashtra"
                            />
                            <TextInput
                              label={"City"}
                              editIcon
                              id="pa_city"
                              onChange={(e) => setPaCity(e.target.value)}
                              value={paCity}
                              placeholder="ex. Pune"
                            />
                          </div>
                          <div className="pincode ">
                            <TextInput
                              type="tel"
                              editIcon
                              id="pa_number"
                              maxlen={"6"}
                              label={"PinCode"}
                              placeholder={"ex.  400001"}
                              onChange={(e) =>
                                pApinCodeValidator(e.target.value)
                              }
                              value={paPinCode}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <CommonDropDown
                        options={gender}
                        selected={genderValue}
                        label="Gender"
                        setSelected={setGenderValue}
                      />
                      <CommonDropDown
                        options={maritalStatus}
                        label="Marital Status"
                        selected={maritalValue}
                        setSelected={setMaritalValue}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="font-bold">Mother Name</label>
                      <TextInput
                        placeholder="Mother Name"
                        value={motherName}
                        onChange={(e) => setMotherName(e.target.value)}
                        width="md:w-[291px]"
                      />
                        <div className="mt-2">
                          <label className="font-bold required">Date of birth</label>
                          <TextInput
                            placeholderUpperCase="uppercase"
                            type="date"
                            placeholder="MM/DD/YYYY"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                            width="md:w-[291px]"
                          />
                        </div>
                        <div className="mt-2">
                          <label className="font-bold">Father Name</label>
                          <TextInput
                            type="text"
                            placeholder="Father Name"
                            value={fatherName}
                            onChange={(e) => setfatherName(e.target.value)}
                            width="md:w-[291px]"
                          />
                        </div>
                      <p className="text-red-500 text-sm">{error}</p>
                    </div>
                    {/* <div className="flex gap-2 mb-2">
                      <ErrorOutlineIcon
                        className="text-primaryH"
                        fontSize="small"
                      />
                      <p className="text-xs flex items-center text-primaryH">
                        Add Correspondence address option as requested.
                      </p>
                    </div> */}
                    <div className="flex items-center">
                      <input
                        id="link-checkbox"
                        type="checkbox"
                        value=""
                        checked={checkboxActive}
                        className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                        onChange={AddressHandler}
                      />
                      <label
                        htmlFor="link-checkbox"
                        className="ml-2 text-sm font-medium text-black "
                      >
                        Use this as the correspondence address
                      </label>
                    </div>
                    {addressActive && (
                      <div className="main_address_container mt-5 ">
                        <label className="font-bold required">
                          Correspondence Address
                        </label>
                        <div className="text_iput">
                          <TextInput
                            label={"Address"}
                            id="correspondence_address"
                            placeholder={"ex.  House no, Flat no, ABC Apt"}
                            onChange={(e) => setCoAddress(e.target.value)}
                            value={coAddress}
                          />
                        </div>
                        <div className="flex flex-row justify-between items-center gap-5">
                          <TextInput
                            label={"State"}
                            id="correspondence_state"
                            onChange={(e) => setCoState(e.target.value)}
                            value={coState}
                            placeholder="ex. Maharashtra"
                          />
                          <TextInput
                            label={"City"}
                            id="correspondence_city"
                            onChange={(e) => setCoCity(e.target.value)}
                            value={coCity}
                            placeholder="ex. Pune"
                          />
                        </div>
                        <div className="pincode w-[47%]">
                          <TextInput
                            type="tel"
                            id="correspondence_number"
                            maxlen={"6"}
                            label={"PinCode"}
                            placeholder={"ex.  400001"}
                            onChange={(e) => pinCodeValidator(e.target.value)}
                            value={coPinCode}
                          />
                        </div>
                        <p className="text-red-500 text-sm">{coError}</p>
                        <div className="flex items-center mt-2">
                          <input
                            id="link-checkbox"
                            type="checkbox"
                            value=""
                            checked={IsCheckbox}
                            onChange={checkBoxHandler}
                            className="w-5 h-5 rounded border-gray-300 text-red-600 ring-0 focus:ring-0"
                          />
                          <label
                            htmlFor="link-checkbox"
                            className="ml-2 text-sm font-medium text-black mt-2"
                          >
                            I confirm that this address is correct as per my
                            knowledge
                          </label>
                        </div>
                      </div>
                      // <div className="mt-[21px]">
                      //   <AddressInput />
                      // </div>
                    )}
                    {openDrawer && (
                      <CommonDrawer
                        onClickHandler={closeDrawer}
                        onProceed={yesHandler}
                        onDenied={noHandler}
                      />
                    )}
                    {verifyDetailsMsgPopup && (
                      <ErrorBottomDrawer
                        info
                        errorMessage="Kindly verify your details carefully."
                        onClickHandler={() => setVerifyDetailsMsgPopup(false)}
                      />
                    )}
                  </div>
                  <div className=" md:w-[235px] hidden md:block md:mr-auto ">
                    <CommonButton
                      arrow
                      label="Continue"
                      filled
                      onClickHandler={continueHandler}
                      id="other-details-continue-button"
                    />
                    {/* <CommonButton label="Use Another Address" location /> */}
                  </div>
                  {/* </div> */}
                </div>
              </BottomCard>
            </div>

            <BottomButtons continueHandler={continueHandler} />

            {/* <div className="fixed bottom-0 p-4 w-full bg-white rounded-sm border shadow-[2px_-4px_16px_-10px_rgba(0,0,0,0.75)] z-10 space-y-3">
        <CommonButton
          label="Continue"
          arrow
          filled
          onClickHandler={continueHandler}
        />
        <CommonButton label="Use Another Address" location />
      </div> */}
          </MsHome>
        </div>
      )}
    </>
  );
};

export default TrOtherDocsFetchDetailsFrame;

function BottomButtons({ continueHandler }) {
  return (
    <div className="fixed bottom-0 short:p-2 p-4 w-full bg-white rounded-sm border shadow-[2px_-4px_16px_-10px_rgba(0,0,0,0.75)] z-10 space-y-3 md:hidden">
      <CommonButton
        label="Continue"
        arrow
        filled
        onClickHandler={continueHandler}
      />
      {/* <CommonButton label="Use Another Address" location /> */}
    </div>
  );
}
