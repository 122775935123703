import { fetchAdhaarDetails, fetchAdhaarDetailsSetu } from "../../services/adhaar-digilocker-service";
import { encrypt,decrypt } from "../../utils/encryptionV2";

export async function fetchCustomerAddressFromAdhaarDigilockerResponse(
  mobileNo,
  requestID,
  panNumber,
  channelUrl
) {
  const data = JSON.stringify({
    mobileNo: mobileNo,
    requestID: requestID,
    panNumber: panNumber,
    channel: channelUrl,
  });
  let encryptedData=encrypt(data)
  let response = await fetchAdhaarDetails({enc_request:encryptedData});
  let decryptedData =decrypt(response?.enc_response)
  console.log(decryptedData)      
  if (decryptedData.success === true) {
    return decryptedData.data;
  }

  return false;
}

export async function fetchCustomerAddressFromAdhaarDigilockerResponseSetu(
  mobileNo,
  requestID,
  panNumber,
  channelUrl,
  state
) {
  const data = JSON.stringify({
    mobileNo: mobileNo,
    requestID: requestID,
    panNumber: panNumber,
    channel: channelUrl,
    state:state
  });
  let encryptedData=encrypt(data)
  let response = await fetchAdhaarDetailsSetu({enc_request:encryptedData});
  let decryptedData =decrypt(response?.enc_response)
  console.log(decryptedData)      
  if (decryptedData.success === true) {
    return decryptedData.data;
  }

  return false;
}

