import { apiInstance } from "./networking";

export const getImage = async (payload) => {
  console.log("payload data", payload)
  const { data = {} } = await apiInstance.post(
    `customer/selfie-upload/`,
    payload
  );
  return data;
};
