import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./Screens/LoginScreen/LoginScreen";
import OtpPage from "./Screens/OtpScreen/OtpScreen";
import HomePage from "./Screens/HomeScreen/HomeScreen";
import KYCHomeScreen from "./Screens/KYCHomeScreen/KYCHomeScreen";
import AryaHomeScreen from "./Screens/KYCHomeScreen/AryaHomeScreen";
import PanDetailsScreen from "./Screens/KYCHomeScreen/PanDetailsScreen";
import AnnualIncomePage from "./Screens/KYCHomeScreen/Frames/AnnualIncome";
import ThankyouPage from "./Components/global-components/CommonSuccess/CommonSuccess.jsx";
// import PehchaanLogin from "./Components/global-components/CommonThankYou/CommonThankYou.jsx";
import UploadScreen from "./Screens/UploadOtherDocsScreen/UploadScreen";
import RedirectTransitionScreen from "./Screens/RedirectTransitionScreen/RedirectTransitionScreen";

import Camerascreen from "./Screens/CameraScreen/Camerascreen";
import AdhaarCardFetchDetailsFrame from "./Screens/KYCHomeScreen/Frames/AdhaarCardFetchDetailsFrame";
import ImageScreen from "./Screens/CameraScreen/ImageScreen";
import OtherDocsFetchDetailsFrame from "./Screens/KYCHomeScreen/Frames/OtherDocsFetchDetailsFrame";
import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import ErrorBottomDrawer from "./Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer";
import PageNotFoundError from "./Components/global-components/PageNotFoundError/PageNotFoundError";
import { apiInstance } from "./services/networking";
import ProtectedRoute from "./Components/global-components/ProtectedRoute/ProtectedRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PartnerOnlyRedirectScreen from "./Screens/PartnerOnlyRedirectScreen/PartnerOnlyRedirectScreen";
import MsTabScreen from "./Screens/marutiSuzukiScreen/MsTabScreen";
import MsCkycScreen from "./Screens/marutiSuzukiScreen/MsCkycScreen";
import { useDispatch, useSelector } from "react-redux";
import { updateMarutiUserDetails } from "./Screens/Redux/partnerChannelNameSlice";
import { updateUserData, userLocation } from "./Screens/Redux/userData";
import HeadlessDueDeligence from "./Screens/HeadlesDueDeligence/HeadlessDueDeligence";
import { marutiUrl } from "./services/maruti-service";
import { showGlobalError } from "./Screens/Redux/globalErrorSlice";
import { storeToken } from "./Screens/Redux/authSlice";
import { getBinaryUserDataPanDob } from "./services/vendorServices/binary-service";
import BinaryLandingPage from "./Screens/vendor/BinaryLandingPage";
import ReactGA from "react-ga4";
import VerifiedPartner from "./Screens/vendor/VerifiedPartner";
import Digitalformsixty from "./Screens/KYCHomeScreen/Frames/Digitalformsixty";
//corporate flow 
import CorporatePanCardLanding from "./Corporate/CorporatePanCardLanding";
import CorporateAddressScreen from "./Corporate/CorporateAddressScreen";
import CorporateDocumentUpload from "./Corporate/CorporateDocumentUpload";
import CorporateAuthorisedDocument from "./Corporate/CorporateAuthorisedDocument";
import CorporateUploadScreen from "./Corporate/CorporateUploadScreen";
import { decrypt } from "./utils/encryptionV2.js";
import AadhaarCardFrameArya from "./Screens/KYCHomeScreen/Frames/AadhaarCardFrame/AadhaarCardFrameArya.jsx";
import TrHomePage from "./Screens/TravelScreen/TrHomePage.jsx";
import RedirectTransitionScreenSetu from "./Screens/RedirectTransitionScreen/RedirectTransitionScreenSetu.jsx";
import AadhaarCardFrameSetu from './Screens/KYCHomeScreen/Frames/AadhaarCardFrame/AadhaarCardFrameDigilocker.jsx'
import TrUploadDocument from "./Screens/TravelScreen/TrUploadDocument.jsx";
import TrOtherDocsFetchDetailsFrame from "./Screens/TravelScreen/TrOtherDocsFetchDetailsFrame.jsx";


export default function HDFCERGOAPP() {
  const [errorMessage, setErorrMessage] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [customerType, setCustomerType] = useState("I");
  const { channelUrl: storedChannelname,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  const { kycTypeSelected } = useSelector((state) => state.userDetails.userData)
console.log({kycTypeSelected})
  const { due } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const errorMsg = useSelector((state) => state.globalError.errorMsg);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/login"
    ) {
      document.documentElement.style.setProperty("--primary-color", "#e94d51");
      document.documentElement.style.setProperty(
        "--secondary-color",
        "rgba(255, 0, 0, 0.1)"
      );
    }
    if (window.location.pathname === "/MsHomePage") {
      let channelName;
      let mTxn = "";
      let isDueValue;
      let subChannelName;
      if (window.location.search.includes("channel")) {
        let url = window.location.search;
        const urlParams = new URLSearchParams(url);
        channelName = urlParams.get("channel");
        subChannelName=urlParams.get("subChannel");
        const ui = urlParams.get("ui");
        mTxn = urlParams.get("txnId");
        isDueValue = urlParams.get("due");
        let subChannel = urlParams.get("subChannel");
        console.log("subChannel",subChannel)

        let config1 = {
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/partner/kyc-status/transaction-id/${mTxn}`,
          headers: {
            "Content-Type": "application/json",
            txnid: localStorage.getItem("txnId") === "null" ?? null,
            token: localStorage.getItem("token"),
          },
        };
  
        axios(config1)
          .then(function (response) {
            console.log("Response",response)
            if (response.data.data.status === "approved" || response.data.data.status === "pending for verification") {
              console.log("Response we grt we user visit again",response.data.data);
              navigate("/Success", {
                state: { kyc_ref_id: response?.data?.data?.kyc_id },
              });
            }
          })
          .catch(function (error) {
            console.log("Token undefiend in kyc-status");
            console.log(error);
          });
        console.log(ui, channelName, isDueValue, due);
        dispatch(
          updateMarutiUserDetails({
            channelUrl: channelName,
            typeOfUI: ui,
            mi_u: mTxn,
            subChannel:subChannel,
            due: isDueValue,
            subChannel:subChannelName
          })
        );
      }
      if (channelName === "maruti" || storedChannelname === "maruti") {
        document.documentElement.style.setProperty(
          "--primary-color",
          "#113569"
        );
        document.documentElement.style.setProperty(
          "--secondary-color",
          "#f4f5f7"
        );
        if (isDueValue === "0") {
          navigate("/duedeligence");
        } else {
          navigate("/msHomePage");
        }
      }
    }
    if (storedChannelname === "maruti") {
      document.documentElement.style.setProperty("--primary-color", "#113569");
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#f4f5f7"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedChannelname, due]); //ignore deps as this useeffect will run on some specific change only

  apiInstance.interceptors.request.use(function (request) {
    //get value from ls
    request.headers.token = localStorage.getItem("token");
    request.headers.txnId = localStorage.getItem("txnId");
    return request;
  });
  axios.interceptors.request.use(function (request) {
    //get value from ls
    request.headers.token = localStorage.getItem("token");
    return request;
  });
  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        // Do something with response error
        console.log("axios",error)
        if(error?.response?.data?.enc_response){
          let decryptedData=decrypt(error?.response?.data?.enc_response)
      
          error={...error,response:{...error?.response,data:{...error?.response?.data,message:decryptedData?.data}}}
          if(decryptedData?.success==false){
            error.response.data.message =decryptedData?.message || "Unable to fetch details, please give it another try";
          }
        }
        console.log("axios",error)
        setOpenDrawer((prev) => !prev);
        setErorrMessage(error?.response?.data?.message || error?.response?.data);
        if (error?.response?.status === 404) {
          toast.error("Internet connectivity test");
        }
        if (error?.response?.status === 401) {
          if (storedChannelname === "maruti") {
            window.location.href = "/msHomePage";
          } else {
            window.location.href = "/login";
          }
        }
        // toast.error(error.response.data.message || error.response.data, {
        //   position: toast.POSITION.TOP_RIGHT,

        return Promise.reject(error);
      }
    );

    apiInstance.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        // Do something with response error
      
        if(error.response.data.enc_response){
          let decryptedData=decrypt(error.response.data.enc_response)
          console.log({decryptedData})
          error={...error,response:{...error.response,data:{...error.response.data,message:decryptedData.data}}}
          if(decryptedData.success==false){
            error.response.data.message =decryptedData?.message || "Unable to fetch details, please give it another try";
          }
        }
        console.log("apiInstance",error)
        if (
          error.response.status === 400 &&
          error.response.data.message ===
          "Mismatch on KYC, Existing KYC being updated with new details"
        ) {
          navigate(kycTypeSelected === "Individual" ? "/kyc" : "/corporatePage", { replace: true });
        }
        if (
          error.response.data.message ===
          "No CKYC number found for the given pan number"
        ) {
          error.response.data.message =
            "No CKYC number found for the given pan number, click ok to proceed with Other Documents";
        
        }
        if (error.response.data.message === "Invalid PAN Number") {
          error.response.data.message =
            "Kindly check the pan number. We coudn’t verify the PAN number digitally.";
        }
        if (
          error.response.data.message ===
          "No information found with the given PAN and DOB combination"
        ) {
          error.response.data.message =
            "Kindly check the details entered. DOB doesn’t match the records against the PAN number in CKYC records. Kindly proceed through Aadhaar.";
        }
        if (
          kycTypeSelected === "Individual" && error.response.data.message === "No record found for given PAN number"
        ) {
          error.response.data.message =
            "We could not fetch any details for the PAN and DOB in CKYC records. Kindly proceed through Aadhaar.";
        }
        if (
          kycTypeSelected === "Corporate" && error.response.data.message === "No record found for given PAN number"
        ) {
          error.response.data.message =
            "We could not fetch any details for the PAN and DOB in CKYC records. Kindly fill the correct detail to proceed.";
        }

        // if both service down if will true
        if (
          kycTypeSelected === "Corporate" && error.response.data.message === "There seems to be a temporary slowdown. Kindly retry after a while or try using alternative method"
        ) {
          setOpenDrawer(true);
          setErorrMessage(error.response.data.message);
        }
        console.log("APIiNSTANCE",error)

        if (!excludeErrorForApis.includes(error?.config.url)) {
          setOpenDrawer(true);
          setErorrMessage(error.response.data.message || error.response.data);
        }
        if (error.response.data.message === "Invalid Request Body") {
          setOpenDrawer(true);
          setErorrMessage("Invalid Ckyc Number");
        }

        if (excludeErrorForApis.includes(error?.config.url) && error.response.data.message==="Duplicate transaction id found" ) {
          setOpenDrawer(true);
          setErorrMessage(error.response.data.message || error.response.data);
          setCustomerType("C")
        }

      if(error.response.data.message === "Gst service is currently down. Please try using OVD journey"){
          setOpenDrawer(true);
          setErorrMessage("Gst service is currently down. Please try using OVD journey");
          setCustomerType("C")
        }

        // if (error.response.status === 404) {
        //   toast.error("Internet connectivity test");
        // }

        if (error.response.status === 401) {
          if (storedChannelname === "maruti") {
            window.location.href = "/msHomePage";
          } else {
            window.location.href = "/login";
          }
        }
        // toast.error(error.response.data.message || error.response.data, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        return Promise.reject(error);
      }
    );
  }, [kycTypeSelected, storedChannelname]);
  const onErrorHandler = (errorMessage) => {
    setOpenDrawer(false);
    dispatch(showGlobalError(null));
    if(customerType==="C" && errorMessage==="Duplicate transaction id found"){
      navigate("/corporatePage",{replace:true})
    }
    // if(customerType==="I" && errorMessage==="Duplicate transaction id found"){
    //   navigate("/kyc",{replace:true})
    // }
    if (errorMessage === "Name mismatch on PAN & Aadhaar, Please try again") {
      navigate("/kyc", { replace: true });
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, [navigate]);

  // useEffect(() => {
  //   // ReactGA.initialize(process.env.REACT_APP_GA);
  //   ReactGA.initialize(process.env.REACT_APP_GA,{
  //     gaOptions:{
  //       cookieFlags:'Secure;SameSite=Lax',
  //     }
  //   });
  //   ReactGA.send({
  //     hitType: "pageview",
  //     page: window.location.pathname + window.location.search,
  //   });
  // }, []);

  return (
    <>
      {/* <ToastContainer limit={2} /> */}
      {openDrawer && (
        <React.Fragment>
          <ErrorBottomDrawer
            errorMessage={errorMessage}
            onClickHandler={onErrorHandler}
          />
        </React.Fragment>
      )}
      {errorMsg && (
        <React.Fragment>
          <ErrorBottomDrawer
            errorMessage={errorMsg}
            onClickHandler={onErrorHandler}
          />
        </React.Fragment>
      )}
      <Routes>

        <Route path="/" element={<LoginPage />} />
        <Route path={LoginScreen} element={<LoginPage />} />
        <Route path={OtpScreen} element={<OtpPage />} />
        {/* //new route added by aniket */}
        {/* <Route path={LoginScreenNew} element={<LoginPage />} /> */}
        {/* <Route path={OtpScreenNew} element={<OtpPage />} /> */}

        <Route path = {digitalFormSixty} element={<Digitalformsixty/>}/>
        <Route path="/binary" element={<BinaryLandingPage />} />
        <Route path="/verified-partner" element={<VerifiedPartner />} />
        <Route element={<ProtectedRoute />}>
          <Route path={HomeScreen} element={<HomePage />} />
          <Route path={KycRoute} element={<KYCHomeScreen />} />
          {/* <Route path={AryaRoute} element={<AryaHomeScreen />} /> */}
          <Route path={AnnualIncome} element={<AnnualIncomePage />} />
          {/* <Route path={Thankyou} element={<ThankyouPage />} /> */}
          <Route path={PanScreen} element={<PanDetailsScreen />} />
          <Route path={AnnualIncome} element={<AnnualIncomePage />} />
          <Route path={PanScreen} element={<PanDetailsScreen />} />
          <Route path={UploadOtherDocDeatils} element={<UploadScreen />} />
          <Route path={CorporateLandingPage} element={<CorporatePanCardLanding />} />
          <Route path={CorporateAddress} element={<CorporateAddressScreen />} />
          <Route path={CorporateUploadDocumnet} element={<CorporateUploadScreen />} />
          <Route path={CorporateAuthorised} element={<CorporateAuthorisedDocument />} />
          <Route path={CorporateDocument} element={<CorporateDocumentUpload />} />
          <Route
            path={RedirectFromDigilocker}
            element={<RedirectTransitionScreen />}
          />
                    {/* for arya digilocker */}
                    <Route path={AryaAadhaar} element={<AadhaarCardFrameArya/>} />

                    <Route path={DigilockerAadhaar} element={<AadhaarCardFrameSetu/>} />
                    <Route
            path={RedirectFromDigilockerSetu}
            element={<RedirectTransitionScreenSetu />}
          />
          <Route path={Camera} element={<Camerascreen />} />
          <Route
            path={AdhaarDetailsScreen}
            element={<AdhaarCardFetchDetailsFrame />}
          />
          <Route path={Image} element={<ImageScreen />} />

          <Route
            path={OtherDocsDetailsScrenn}
            element={<OtherDocsFetchDetailsFrame />}
          />
          {/* <Route path={notFound} element={<PageNotFoundError />} /> */}
          <Route path={MsCkyc} element={<MsCkycScreen />} />
          {/* <Route path={TrCkyc} element={<TrCkycScreen />} /> */}
          <Route path={TrUploadDocumentScreen} element={<TrUploadDocument />} />
          <Route
            path={TrOtherDocsDetailsScreen}
            element={<TrOtherDocsFetchDetailsFrame />}
          />
        </Route>
        <Route path={notFound} element={<PageNotFoundError />} />
        <Route
          path={partnerDigilockerScreen}
          element={<PartnerOnlyRedirectScreen />}
        />
        <Route path={DueDeligence} element={<HeadlessDueDeligence />} />
        <Route path={MsTabsScreen} element={<MsTabScreen />} />
        <Route path={TrHomePageScreen} element={<TrHomePage />} />
         {/* Remove from protected route and add here */}
         <Route path={Thankyou} element={<ThankyouPage />} />
         <Route path={AryaRoute} element={<AryaHomeScreen />} />

      </Routes>
    </>
  );
}

// export const Kyc = "/kyc/start";
export const KycRoute = "/kyc";
export const AryaRoute = "/lite-kyc";
// export const AddressRoute = "/kyc/address";
// export const KycAdditionalDataRoute = "/kyc/additional-data";
// export const UploadDocRoute = "/kyc/uploadDocuments";
// export const DownloadPassportRoute = "/kyc/downloadPassport";
// export const KycFaceVerification = "/kyc/face-verification";
// export const KycThanksMsgRoute = "/kyc/success";
// export const ConclusionRoute = "/conclusionScreen";
export const LoginScreen = "/login";
// export const LoginScreenNew = "/pehchaanPortalLogin";
export const OtpScreen = "/otp";
export const OtpScreenNew = "/pehchaanPortalOtp";
export const HomeScreen = "/home";
export const PanScreen = "/panDetails";
export const AnnualIncome = "/Income";
export const Thankyou = "/Success";
export const UploadOtherDocDeatils = "/uploadDocument";
export const RedirectFromDigilocker = "/redirect/digilocker";
export const RedirectFromDigilockerSetu = "/redirect/ekyc";
export const AdhaarDetailsScreen = "/adhaarDetails";
export const Camera = "/webcam";
export const Image = "/image";
export const OtherDocsDetailsScrenn = "/otherDocsDetails";
export const partnerDigilockerScreen = "/partnerDigilocker";
export const MsCkyc = "/msCkyc";
export const notFound = "*";
export const digitalFormSixty = "/digitalFormSixty"
//corporate flow route 
export const CorporateLandingPage = "/corporatePage"
export const CorporateAddress = "/corporateAddressScreen"
export const CorporateUpload = "/corporateUploadScreen"
export const CorporateDocument = "/documentUpload"
export const CorporateAuthorised = "/authorisedDocument"
export const CorporateUploadDocumnet = "/coroporateUpload"

//MS(maruti suzuki) screen route
export const MsTabsScreen = "/msHomePage";
export const DueDeligence = "/duedeligence";
// export const MsCkyc = "/msCkycPage";

// Travel screen route
export const TrCkyc = "/trCkyc";
export const TrHomePageScreen = "/trHomePage";
export const TrUploadDocumentScreen = "/trUploadDocument";
export const TrOtherDocsDetailsScreen = "/trOtherDocsDetails";
// export const TravelDueDeligence = "/travelDuedeligence";

//aray aadhaar
export const AryaAadhaar = "/arya-digilocker"
export const DigilockerAadhaar = "/digilocker-ekyc"

export const scrollIntoViewFunc = (id) => {
  document
    ?.getElementById(id)
    ?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
};
export const allowedFileSize = 10240;
export const allowedFileSizeMb = 10;
export const allowedFileSizeMb2 = 5;
export const MAX_ATTEMPT_TIME_LIMIT = 900; //15 min
export const gaFunction = (category, action) => {
  ReactGA.event({
    category: category,
    action: action,
    // label: "your label", // optional
    // value: 99, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
};

export const formValidator = (arr) => {
  let count = 0;
  let fName = [];
  // eslint-disable-next-line array-callback-return
  arr.filter((a) => {
    if (a.fieldLength === 0) {
      count++;
      fName.push(a.fieldName);
    }
  });
  if (count >= 1) {
    return fName.join(" , ");
  } else {
    return false;
  }
};

export const OEM_List = ["maruti", "binary", "mahindra", "tvs", "jeep", "hero", "travel"];

const excludeErrorForApis = ["primary/corp-ckyc-details", 
  "primary/cin-details", 
  "primary/gst-details"]


// "primary/gst-details", -- USED FOR PARTNER
// "primary/v2/gst-details" -- USED FOR OWN PORTAL