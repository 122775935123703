import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../../../Components/global-components/CommonButton/CommonButton";
import TextInput from "../../../../Components/global-components/CommonTextInput/TextInput";
import HiddenWrapper from "../../../../Components/global-components/hiddenWrapper/HiddenWrapper";
import ToggleButton from "../../../../Components/kyc-components/ToggleButton";
import {
  gaFunction,
  MAX_ATTEMPT_TIME_LIMIT,
  scrollIntoViewFunc,
} from "../../../../Router";
import styles from "../../../LoginScreen/LoginScreen.module.css";
import { userPanNumberDetails } from "../../../Redux/AdhaarRedirectInfoSlice";
import { showGlobalError } from "../../../Redux/globalErrorSlice";
import { userPanNumber } from "../../../Redux/panCardSlice";
import { toggleTabs } from "../../../Redux/slice";
import { activeStepper } from "../../../Redux/stepperSlice";
import { toggleButton } from "../../../Redux/switchToggleSlice";
import OtherDocsFrame from "../OtherDocsFrame";
import Cookies from "universal-cookie";
import ErrorBottomDrawer from "../../../../Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer";
import {
  fetchPanCardInfoInterface,
  generateDigilockerURL,
  generateDigilockerURLArya,
} from "./AadhaarCardFrame.interface";
import { useNavigate } from "react-router-dom";
// import { Puff } from  'react-loader-spinner'
const AadhaarCardFrameArya = () => {
  const userDetails = useSelector((state) => state.userDetails.userData);
  const cookies = new Cookies();
  const { panNumber: reduxPanNumber } = userDetails;
  const [panCardNumber, setPanCardNumber] = useState(reduxPanNumber);
  const [panError, setPanError] = useState("");
  const [isKyc, setIsKyc] = useState(false);
  const dispatch = useDispatch();
  const [loadFetchPanCard, setLoadFetchPanCard] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(true);
  const istoggle = useSelector((state) => state.switchButton.activeSwitch);
  const kyc_id = useSelector((state) => state.kycDetails.kycRefId);
  const [msgPopup, setMsgPopup] = useState(null);
  const navigate = useNavigate();
  // ADD FOR DEUPED LOGIC IN ADHAAR
  const { mi_u,channelUrl,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
 let txn_id = mi_u || localStorage.getItem("txnId");
 let doc_type = "AADHAAR"
  const fetchPanCardInfo = async () => {
    console.log("AADHAAR DEDUPED LOGIC",panCardNumber, kyc_id , txn_id , doc_type)
    let data = await fetchPanCardInfoInterface(panCardNumber, kyc_id , txn_id , doc_type,channelUrl);
    return data;
  };
  const getDigilockerURL = async () => {
    const data = { kyc_id };
    const response = await generateDigilockerURLArya(data);
    console.log("arya response ",response)
    if (response === false) {
      // alert("Error in generating Digilocker URL");
      return false;
    } else {
      return response.url;
    }
  };
  const digiLockerRedirectHandler = async () => {
    gaFunction("digilocker", "fetch details from digilocker clicked");
    if (loadFetchPanCard) return;
    // dispatch(activeStepper(1));
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    if (String(panCardNumber).length === 0 || !regexPan.test(panCardNumber)) {
      scrollIntoViewFunc("pan_number_adhaar_frame");
      setPanError("Please provide valid pan");
      return;
    } else if (!isDeclarationChecked) {
      setPanError("Please accept declaration");
      scrollIntoViewFunc("adhar_dec_checkbox");
      return;
    }
    if (true) {
      setLoadFetchPanCard(true);
      let panCardResponse = await fetchPanCardInfo();
      console.log("panCardResponse", panCardResponse)
      if(panCardResponse?.message === "KYC Limit exceed"){
        console.log("KYC Limit exceed AADHAAR");
        setLoadFetchPanCard(false);
        setMsgPopup(true)
        return;
      }
      if (panCardResponse?.exists) {
        console.log("deduped workin in AADHAR");
        dispatch(activeStepper(3));
        setMsgPopup(false)
        navigate("/Success", {
          state: {
            kyc_ref_id: panCardResponse?.kyc_id,
            typeOfKyc: panCardResponse?.status,
          },
        });
      } else if (!panCardResponse?.success && !panCardResponse?.message === "KYC Limit exceed" ) {
        // if (true) {
        setLoadFetchPanCard(false);
        setPanError("Please provide valid pan");
        scrollIntoViewFunc("pan_number_adhaar_frame");
        return;
      } else {
        //if pancard number is valid then generate the digilocker url
        let digilockerURL = await getDigilockerURL();
        if (!digilockerURL) {
          setLoadFetchPanCard(false);
          return;
        }
        dispatch(activeStepper(1));
        // redirect to link
        setLoadFetchPanCard(false);
        window.location.href = digilockerURL;
        // //if pancard number is valid then generate the digilocker url
        // // OLD CODE FLOW
        // let digilockerURL = await getDigilockerURL();
        // if (!digilockerURL) {
        //   setLoadFetchPanCard(false);
        //   return;
        // }
        // dispatch(activeStepper(1));
        // // redirect to link
        // setLoadFetchPanCard(false);
        // window.location.href = digilockerURL;
      } 
  }
  };
  const handlePanCardInput = (panCardNumber) => {
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    panCardNumber = String(panCardNumber).toUpperCase();
    panCardNumber=panCardNumber?.replace(/\s/g,"");
    if (panCardNumber.length <= 10) {
      dispatch(userPanNumberDetails(panCardNumber || ""));
      dispatch(userPanNumber(panCardNumber || ""));
      setPanCardNumber(panCardNumber);
    }
    setPanError("");
    if (!regexPan.test(panCardNumber)) {
      setPanError("Please provide valid pan");
    }
  };
  const toggleChangeHandler = (e) => {
    dispatch(toggleButton(e.target.checked));
    // dispatch(toggleTabs("otherDocs"));
    navigate("/digitalFormSixty", { state: { methodOfKyc: "aadhaar" } });
  };
  const clickHandler = () => {
    setIsKyc((prev) => !prev);
    dispatch(toggleTabs("otherDocs"));
    // dispatch(toggleButton(false));
  };
  useEffect(() => {
    let reduxPanValue=reduxPanNumber;
    reduxPanValue=reduxPanValue?.replace(/\s/g,"");
    if (reduxPanValue?.length == 10) {
     setPanCardNumber(reduxPanValue);
    }else{
      setPanCardNumber("");
    }
  }, [reduxPanNumber]);
  useEffect(() => {
    setLoadFetchPanCard(false);
    dispatch(activeStepper(0));
  }, []);
  return (
    <>
      {isKyc || istoggle === false ? (
        navigate("/digitalFormSixty", { state: { methodOfKyc: "aadhaar" } })
      ) : (
        // <OtherDocsFrame />
        <>
          {!isKyc && istoggle === false ? (
            <>
            {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") &&
              <div className="flex  gap-[10px]  items-center mt-[16px] max-w-[100%]">
                <p>Do you have a valid PAN Number?</p>
                <ToggleButton
                  onChange={toggleChangeHandler}
                  checked={istoggle}
                />
              </div>
}
              {/* {istoggle === false ? null : (
                  <div className="mt-5">
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    >
                      PAN No.
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-sm focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                      placeholder="Enter Valid PAN"
                      autoComplete="off"
                      required
                      onChange={(e) => {
                        handlePanCardInput(e.target.value);
                      }}
                      value={panCardNumber}
                    />
                    {panError && (
                      <p className="p-1 text-red-700">
                        Please provide a valid PAN
                      </p>
                    )}
                  </div>
                )} */}
              <div className="h-1/5 md:h-0"></div>
              <div className="w-full bg-white rounded-sm z-10 space-y-3 mt-7 md:w-[300px] mr-auto ml-auto">
                <CommonButton
                  label="Fetch details from Digilocker"
                  filled
                  arrow={!loadFetchPanCard}
                  onClickHandler={digiLockerRedirectHandler}
                  loading={loadFetchPanCard}
                />
              </div>
              <div className={styles.spacer}></div>
             {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") && <div className="text-center underline text-primaryH cursor-pointer mt-3 mb-[20px] md:mb-0 md:mt-2  md:text-left">
                <p onClick={clickHandler}>
                  Use other documents to submit your KYC.
                </p>
              </div>
}
              <div className={styles.spacer}></div>
              <div className="mt-3">
                <p className="leading-4 fs-12 fw-500 mt-3 text-xs">
                  Note: OTP is shared by UIDAI on the mobile number linked with
                  the Aadhaar number in UIDAI database.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className=" overflow-scroll">
                {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") && <div className="flex   gap-[10px]  items-center mt-[16px]">
                  <p>Do you have a valid PAN Number?</p>
                  <ToggleButton
                    onChange={toggleChangeHandler}
                    checked={istoggle}
                  />
                </div>}
                <div className="mt-5" id="pan_number_adhaar_frame">
                  <label
                    htmlFor="aadhaar_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                  >
                    PAN No.
                  </label>
                  <TextInput
                    type="text"
                    id="pan_number_adhaar_frame"
                    placeholder="Enter Valid PAN"
                    autoComplete="off"
                    required
                    width="md:w-[300px]"
                    onChange={(e) => {
                      handlePanCardInput(e.target.value);
                    }}
                    success={
                      panError || panCardNumber?.length <= 0 ? false : true
                    }
                    value={panCardNumber}
                  />
                  {panError && (
                    <p className="pt-2 pb-0 text-red-700">{panError}</p>
                  )}
                </div>
                {/* <div className="flex mt-2">
                  <input
                    id="adhar_dec_checkbox"
                    type="checkbox"
                    value={isDeclarationChecked}
                    checked={isDeclarationChecked}
                    className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                    onClick={(e) => {
                      setIsDeclarationChecked(e.target.checked);
                      if (e.target.checked) setPanError("");
                    }}
                  />
                  <label
                    htmlFor="link-checkbox"
                    className="ml-2 text-sm font-medium text-black "
                  >
                    I hereby provide my consent to HDFC ERGO General Insurance
                    Company Limited to retrieve/ verify my KYC documents like
                    Address /ID proof and photograph, from the CERSAI portal
                    against my PAN No. I understand that the said KYC documents
                    shall be relied upon by the Company for processing my
                    policy/refunds/ Claims.
                  </label>
                </div> */}
              </div>
              {/* <div className="h-1/5"></div> */}
              <div className="w-full bg-white rounded-sm z-10 space-y-3 md:mt-0 mt-7 md:w-[300px] md:mt-[10px]">
                <CommonButton
                  label="Fetch details from Digilocker"
                  filled
                  id="digiocker_button"
                  arrow={!loadFetchPanCard}
                  onClickHandler={digiLockerRedirectHandler}
                  loading={loadFetchPanCard}
                />
              </div>
              <div className={styles.spacer}></div>
              {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") && <div
                className="text-center underline cursor-pointer mt-3 mb-[20px] md:mb-0 md:mt-2  md:text-left"
                style={{ color: "blue" }}
              >
                <p onClick={clickHandler}>
                  Use other documents to submit your KYC.
                </p>
              </div>}
              <div className={styles.spacer}></div>
              <div className="mt-3 md:mt-0 md:hidden">
                <p className="leading-4 fs-12 fw-500 mt-3 text-xs md:text-left mb-3">
                  Note: OTP is shared by UIDAI on the mobile number linked with
                  the Aadhaar number in UIDAI database.
                </p>
              </div>
            </>
          )}
          {msgPopup && (
            <ErrorBottomDrawer
              info
              // errorMessage="We were unable to create your KYC due to your limit being exceeded."
              errorMessage="We are unable to create New pehchaan ID as pehchaan ID already exist for the desired PAN, request to use same.
              To find existing Pehchaan ID already created for the PAN, use alternative pehchaan creation option(Aadhaar/ OVD)."
              onClickHandler={() => setMsgPopup(false)}
            />
          )}
        </>
      )}
    </>
  );
};
export default AadhaarCardFrameArya;