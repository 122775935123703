/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from "react";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import styles from "./uploadDocs.module.css";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import { useSelector, useDispatch } from "react-redux";
import Loadingoverlay from "../../Components/global-components/LoadingOverlay/Loadingoverlay";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveAs } from "file-saver";
import truncate from "truncate";
import {
  documentDetail,
  fileUpload,
  formSixtyDocument,
  formSixtyDocumentName,
  kycId,
  previewIamgeURL,
} from "../Redux/otherDocsSlice";
import PreviewDocument from "../KYCHomeScreen/Frames/PreviewDocument";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserData } from "../Redux/userData";
import { voterIdUserDetails } from "../Redux/voterIdDetails";
import Tab from "../../Components/kyc-components/Tab";
import {
  formSixtyUplaodDocument,
  submitDocuments,
} from "../../services/otherDocs";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { kycRefIdAddNewKyc } from "../Redux/kycDetailsSlice";
import { activeStepper } from "../Redux/stepperSlice";
import Cookies from "universal-cookie";
import { showGlobalError } from "../Redux/globalErrorSlice";
import { allowedFileSizeMb2, gaFunction, MAX_ATTEMPT_TIME_LIMIT } from "../../Router";
import { optionsValue } from "../Redux/otherdocsDropDownValueSlice";
import { addUserImage , addUserImageName, changeSelfieUrl } from "../Redux/CameraSlice";
import CommonWebcam from "../../Components/global-components/CommonWebcam/CommonWebcam";
import { makeStyles } from "@mui/styles";
import { getImage } from "../../services/camera-service";

const TrUploadDocument = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const panNumber = useSelector((state) => state.panDetails.userPanNumber);
  const { channelUrl: channel, mi_u: txn_id } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const { channel: travelChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.travelUserDetails
  );

  const { frontImageURL, backImageURL } = useSelector(
    (state) => state.uploadedFile.previewImage
  );

  const frontFileName = useSelector(
    (state) => state.uploadedFile.documentImage.frontFile
  );
  const backFileName = useSelector(
    (state) => state.uploadedFile.documentImage.backFile
  );

  const { phoneNumber } = useSelector((state) => state.userDetails.userData);
  const [frontpreview, setFrontPreview] = useState(false);
  const [backPreview, setBackPreview] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [frontImage, setFrontImage] = useState(null);
//   new File([frontImageURL], frontFileName, {
//     type: "image/jpg",
//     })
  const [backImage, setBackImage] = useState(null);
//   new File([backImageURL], backFileName, {
//     type: "image/jpg",
//     })
  const dispatch = useDispatch();
  const webcamRef = useRef(null);

  const slectedValue = useSelector(
    (state) => state.dropDownValue.selectedValue
  );

  const formSixty = useSelector(
    (state) => state.uploadedFile.formSixtyDocument
  );
  const formSixtyName = useSelector(
    (state) => state.uploadedFile.formSixtyDocumentName
  );
  const { kycRefId } = useSelector((state) => state.kycDetails);

  const istoggle = useSelector((state) => state.switchButton.activeSwitch);
  const { digitalForm60Id }=useSelector((state)=>state.digitalFormSixty.formSixtyData);
  const formSixtyString = useSelector((state) => state?.digitalFormSixty.formSixtyData.formSixtyString);
  const {userImage, userImageName} = useSelector((state) => state.CameraImage);
  const [localUserImage, setLocalUserImage] = useState(null);
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 25rem)",
      textAlign: "center",
      marginTop: "-55px",
    },
    imgBox: {
      maxWidth: "80%",
      maxHeight: "80%",
      margin: "10px",
    },
    img: {
      height: "inherit",
      maxWidth: "inherit",
    },
    input: {
      display: "none",
    },
  }));
  const classes = useStyles();

  //   front image uploader\
  const frontFileUploadHandler = (e) => {
    if (e.target.files[0]?.type === "application/pdf") {
      return dispatch(
        showGlobalError(
          "PDF is not allowed. Supported file formats are png, jpeg, jpg and heic."
        )
      );
    }
    if (e.target.files[0]?.size > 5242880) {
      return dispatch(
        showGlobalError(
          "File size exceeds maximum limit. Maximum allowed file size is 5 MB"
        )
      );
    }
    if (e.target.files[0]) {
      dispatch(fileUpload({ frontFile: e.target.files[0].name }));
      setFrontImage(e.target.files[0]);
      dispatch(
        previewIamgeURL({
          frontImageURL: URL.createObjectURL(e.target.files[0]),
        })
      );
    }
  };
  //back image uploader
  const backFileUploadHandler = (e) => {
    if (e.target.files[0]?.type === "application/pdf") {
      return dispatch(
        showGlobalError(
          "PDF is not allowed. Supported file formats are png, jpeg, jpg and heic."
        )
      );
    }
    if (e.target.files[0]?.size > 5242880) {
      return dispatch(
        showGlobalError(
          "File size exceeds maximum limit. Maximum allowed file size is 5 MB"
        )
      );
    }
    if (e.target.files[0]) {
      dispatch(fileUpload({ backFile: e.target.files[0].name }));
      setBackImage(e.target.files[0]);
      dispatch(
        previewIamgeURL({
          backImageURL: URL.createObjectURL(e.target.files[0]),
        })
      );
    }
  };

  const closePreview = () => {
    setFrontPreview(false);
    setBackPreview(false);
  };

  const openFrontImageHandler = () => {
    setFrontPreview(true);
  };
  const openBackImageHandler = () => {
    setBackPreview(true);
  };

  const validateImage = () => {
    if (frontFileName && backFileName && localUserImage) {
      return false;
    }
    dispatch(showGlobalError("Please upload document"));
    return true;
  };

  let convertedFiles = async () => {
    console.log("UPLOAD -------- IMAGE" ,  frontImageURL)
    let frontImageConversionToFile = null;
    let file = null;
    try{
    await fetch(frontImageURL)
      .then((res) => res.blob())
      .then((blob) => {
        frontImageConversionToFile = blob;
        let blobToFileFront = new File([blob], frontFileName, {
          type: frontImageConversionToFile.type,
        });
        file = blobToFileFront;
      });
    } catch (err){console.log("convertedFiles catch err", err)}
      console.log("UPLOAD -------- RESULT" ,  file)
    return file;
  };

  let convertedFilesSelfie = async () => {
    console.log("UPLOAD -------- userImage" ,  userImage)
    let frontImageConversionToFile = null;
    let file = null;
    try{
    await fetch(userImage)
      .then((res) => res.blob())
      .then((blob) => {
        frontImageConversionToFile = blob;
        let blobToFileFront = new File([blob], userImageName, {
          type: "image/png",
        });
        file = blobToFileFront;
      });
    } catch(err){console.log("convertedFilesSelfie catch err", err)}
      console.log("UPLOAD -------- RESULT userImage" ,  file)
    return file;
  };


  function base64ToBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }


  // formSixty Converter function
  let formSixtyFileConverter = async () => {
    let formSixtyConversionToFile = null;
    let file = null;
    const contentType = 'application/pdf'; 
    const blob = base64ToBlob(formSixtyString, contentType);
    formSixtyConversionToFile = blob;
    let blobFileToFormSixty = new File([blob], "FormSixty.pdf", {
    type: formSixtyConversionToFile.type,
    });
    file = blobFileToFormSixty;
    console.log(file);
    return file;   
  };

  const backHandler = () => {
    // navigate("/kyc");
    console.log("back handler")
  };
  const backDeleteHandler = () => {
    dispatch(fileUpload({ backFile: "" }));
    dispatch(previewIamgeURL({ backImageURL: "" }));
  };
  const frontDeleteHandler = () => {
    dispatch(fileUpload({ frontFile: "" }));
    dispatch(previewIamgeURL({ frontImageURL: "" }));
  };

  const successHandler = async () => {
    if (validateImage()) return;
    setIsloading(true);
    let myFormData = new FormData();
    convertedFiles().then((frontBlobToFile) => {
      myFormData.append("front", frontImage || frontBlobToFile);
      myFormData.append("back", backImage);
      myFormData.append("mobileNumber", phoneNumber);
      myFormData.append("pan", panNumber);
      // if (!cookies.get("ovd_attempts")) {
      //   cookies.set("ovd_attempts", 0);
      // }
      // cookies.set("ovd_attempts", +cookies.get("ovd_attempts") + 1);
      if (true) {
        // slectedValue =
        submitDocuments(
          {
            slectedValue:
            slectedValue === "maskedAadhaar" ? "aadhaar" : travelChannel === "travel" ? "passport" : slectedValue,
            panNumber,
            kycRefId,
            channel: travelChannel === "travel" ? travelChannel : channel,
            isAadhaarMasked: slectedValue === "maskedAadhaar" ? true : false,
            txnId: localStorage.getItem("txnId"),
          },
          myFormData
        )
          .then(function (response) {
            if (response?.success === true) {
              setIsloading(false);

              //   Selfie upload
              try{
                let FormData = require("form-data");
                let data = new FormData();
                convertedFilesSelfie().then((file) => {
                    data.append("front", file || localUserImage);
                    data.append("kycRefId", kycRefId || response?.data?._id);
                    getImage(data)
                    .then(function (response) {
                        console.log("response getImage", response)
                        if (response.success === true) {console.log("Selfie uploaded successfully")}
                    })
                })
              } catch(err){
                console.log("selfie catch error", err);
              }

              if (frontImageURL && backImageURL !== "") {
                dispatch(activeStepper(1));
                dispatch(updateUserData({ name: response.data?.fullName }));
                navigate("/trOtherDocsDetails", {
                  state: {
                    isRouteAllowed: true,
                  },
                });
                dispatch(kycId(response?.data?._id));
                dispatch(kycRefIdAddNewKyc(response?.data?._id || ""));
                dispatch(voterIdUserDetails(response?.data?.fullName));
              } else {
                alert("You have not upload your document");
              }
              dispatch(
                documentDetail({
                  name: response?.data?.fullName,
                  address: response?.data?.permanentAddress,
                  dob: response?.data?.dob || "",
                  fatherName: response?.data?.fatherName || "",
                  reduxGenderValue: response?.data?.gender,
                  permanentCity: response?.data?.permanentCity,
                  permanentDistrict: response?.data?.permanentDistrict,
                  permanentCountry: response?.data?.permanentCountry,
                  permanentState: response?.data?.permanentState,
                  permanentPincode: response?.data?.permanentPincode,
                  permanentAddress: response?.data?.shortenedAddress,
                  passportNumber: response?.data?.passportNumber
                })
              );
              dispatch(addUserImage(response?.data?.selfieURL || response?.data?.photo));
            }
          })
          .catch(function (error) {
            // alert(error);
            setIsloading(false);
            backDeleteHandler();
            frontDeleteHandler();
            backHandler();
          });
      } else {
        // setIsloading(false);
        // dispatch(
        //   showGlobalError(
        //     "Please try again after 15 Minutes. Maximum attempt exceeded for OVD."
        //   )
        // );
        // cookies.set("ovd_attempts", +cookies.get("ovd_attempts"), {
        //   expires: new Date(Date.now() + MAX_ATTEMPT_TIME_LIMIT * 1000),
        // });
        // dispatch(formSixtyDocument(""));
        // dispatch(formSixtyDocumentName(""));
        // dispatch(fileUpload({ frontFile: "" }));
        // dispatch(
        //   previewIamgeURL({
        //     frontImageURL: "",
        //   })
        // );
        // dispatch(optionsValue("passport"));
        // frontDeleteHandler();
        // backDeleteHandler();
        // navigate("/kyc", { replace: true });
        // return;
      }
    });
  };
  const formSixtySubmitHandler = async () => {
    if (validateImage()) return;
    setIsloading(true);
    let myFormData = new FormData();
    await formSixtyFileConverter().then((formSixtyFile) => {
      myFormData.append("formSixty", formSixtyFile);
    });

    await convertedFiles().then((frontBlobToFile) => {
      myFormData.append("front", frontImage || frontBlobToFile);
      myFormData.append("back", backImage);
    });
    myFormData.append("mobileNumber", phoneNumber);
    // if (!cookies.get("ovd_attempts")) {
    //   cookies.set("ovd_attempts", 0, {
    //     expires: new Date(Date.now() + MAX_ATTEMPT_TIME_LIMIT * 1000),
    //   });
    // }
    // if (+cookies.get("ovd_attempts") <= 3) {
    //   cookies.set("ovd_attempts", +cookies.get("ovd_attempts") + 1, {
    //     expires: new Date(Date.now() + MAX_ATTEMPT_TIME_LIMIT * 1000),
    //   });
    // }
    if (true) {
      formSixtyUplaodDocument(
        {
          slectedValue:
            slectedValue === "maskedAadhaar" ? "aadhaar" : slectedValue,
          txn_id,
          channel,
          kycRefId,
          isAadhaarMasked: slectedValue === "maskedAadhaar" ? true : false,
          digitalForm60Id: digitalForm60Id,
        },
        myFormData
      )
        .then(function (response) {
          dispatch(voterIdUserDetails(response?.data?.fullName));
          dispatch(kycId(response?.data?._id));
          dispatch(kycRefIdAddNewKyc(response?.data?._id || ""));
          dispatch(updateUserData({ name: response.data?.fullName }));
          dispatch(
            documentDetail({
              name: response?.data?.fullName,
              address: response?.data?.permanentAddress,
              dob: response?.data?.dob || "",
              fatherName: response?.data?.fatherName || "",
              reduxGenderValue: response?.data?.gender,
              permanentCity: response?.data?.permanentCity,
              permanentDistrict: response?.data?.permanentDistrict,
              permanentCountry: response?.data?.permanentCountry,
              permanentState: response?.data?.permanentState,
              permanentPincode: response?.data?.permanentPincode,
              permanentAddress: response?.data?.shortenedAddress,
            })
          );
          dispatch(addUserImage(response?.data?.selfieURL));
          setIsloading(false);
          dispatch(activeStepper(1));
          if (response.success === true) {
            navigate("/otherDocsDetails", {
              state: {
                isRouteAllowed: true,
              },
            });
          }
        })
        .catch((err) => {
          setIsloading(false);
          backDeleteHandler();
          frontDeleteHandler();
          backHandler();
        });
    } else {
      // setIsloading(false);
      // dispatch(
      //   showGlobalError(
      //     "Please try again after 15 Minutes. Maximum attempt exceeded for OVD."
      //   )
      // );
      // // cookies.set("ovd_attempts", +cookies.get("ovd_attempts"), {
      // //   expires: new Date(Date.now() + MAX_ATTEMPT_TIME_LIMIT * 1000),
      // // });
      // dispatch(formSixtyDocument(""));
      // dispatch(formSixtyDocumentName(""));
      // dispatch(fileUpload({ frontFile: "" }));
      // dispatch(
      //   previewIamgeURL({
      //     frontImageURL: "",
      //   })
      // );
      // dispatch(optionsValue("passport"));
      // frontDeleteHandler();
      // backDeleteHandler();
      // navigate("/kyc", { replace: true });
      // return;
    }
  };

  const capture = useCallback((imageSrc) => {
    // const imageSrc = webcamRef.current.getScreenshot();
    console.log("imageSrc", imageSrc);
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], new Date().valueOf(), {
          type: "image/jpeg",
        });
        const userImage = URL.createObjectURL(file);
        dispatch(addUserImage(userImage));
        dispatch(changeSelfieUrl(true));
        dispatch(addUserImageName(file.name));
        // navigate("/image", { state: { isRouteAllowed: true } });
      });
  }, [webcamRef, dispatch]);

  const captureGallary = () => {
    gaFunction("Camera", "Selfie Captured");
    document.getElementById("icon-button-file-gallary").click();
    dispatch(changeSelfieUrl(true));
  };

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const userImage = URL.createObjectURL(file);
        dispatch(addUserImage(userImage));
        dispatch(changeSelfieUrl(true));
        dispatch(addUserImageName(file.name));
        setLocalUserImage(file);
        console.log("handleCapture userImage", userImage)
        // navigate("/image", { state: { isRouteAllowed: true } });
      }
    }
  };

  const downloadFrontHandler = () => {
    saveAs(frontImageURL, "image.jpg");
  };

  const downloadBackHandler = () => {
    saveAs(backImageURL, "image.jpg");
  };

//   useEffect(() => {
//     if (!istoggle && !digitalForm60Id?.length) {
//       navigate("/kyc");
//     }
//   }, [digitalForm60Id?.length, istoggle]);

  const ovdSubmitHandler = () => {
    gaFunction("OVD", "OVD Submit Clicked");
    if (istoggle) {
      successHandler();
    } else {
      formSixtySubmitHandler();
    }
  };
  useEffect(() => {
    dispatch(
      documentDetail({
        dob: "",
        fatherName: "",
      })
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <div className={styles["uploadDocs_container"]}>
          <MsHome>
            <BannerImage>
              {/* <MdKeyboardBackspace
              size={30}
              style={{ marginTop: "10px", marginLeft: "10px" }}
              onClick={backHandler}
            /> */}
              <LogoContainer />
            </BannerImage>
            <BottomCard max>
              <div className="h-full ">
                {/* <div> */}
                <div className="hidden ">
                  <div className="p-[10px]"></div>
                  <label className="md:text-xl">
                    <b>KYC Document</b>
                  </label>
                  <p className="hidden md:block md:text-sm">
                    let's fetch your kyc details using
                  </p>
                  <div className="p-[10px]"></div>
                  <Tab />
                </div>
                <div className="md:shadow-lg md:rounded-lg md:p-4 overflow-auto h-full w-full md:relative">
                  {frontImageURL === "" ? (
                    <Button
                      className="lato-family"
                      sx={{
                        border: "1px solid var(--primary-color)",
                        backgroundColor: "var(--secondary-color)",
                        color: "black",
                        width: "100%",
                      }}
                      component="label"
                    >
                      {travelChannel === "travel" ? "Upload First Page" : "Upload Front Side"}
                      <input
                        id="front_image"
                        hidden
                        accept="image/jpeg,image/png,image/x-eps,image/jpg,image/heic"
                        multiple
                        type="file"
                        onChange={frontFileUploadHandler}
                      />
                    </Button>
                  ) : (
                    <div className="flex border justify-between  p-4">
                      <div>
                        <FileCopyIcon />
                      </div>
                      <div>
                        <span>{truncate(frontFileName, 10)}</span>
                      </div>
                      <div className="flex flex-row gap-5">
                        <VisibilityIcon onClick={openFrontImageHandler} />

                        {frontpreview && (
                          <PreviewDocument
                            imgUrl={frontImageURL}
                            closePreview={closePreview}
                          />
                        )}
                        <DownloadIcon onClick={downloadFrontHandler} />
                        <DeleteIcon onClick={frontDeleteHandler} />
                      </div>
                    </div>
                  )}
                  <div className="p-4"></div>
                  {backImageURL === "" ? (
                    <Button
                      sx={{
                        border: "1px solid var(--primary-color)",
                        backgroundColor: "var(--secondary-color)",
                        color: "black",
                        width: "100%",
                      }}
                      component="label"
                    >
                      {travelChannel === "travel" ? "Upload Last Page" : "Upload Back Side"}
                      <input
                        id="back_img"
                        hidden
                        accept="image/jpeg,image/png,image/x-eps,image/jpg,image/heic"
                        multiple
                        type="file"
                        onChange={backFileUploadHandler}
                      />
                    </Button>
                  ) : (
                    <div className="flex border justify-between p-4">
                      <div>
                        <FileCopyIcon />
                      </div>
                      <div>
                        <span>{truncate(backFileName, 10)}</span>
                      </div>
                      <div className="flex flex-row gap-5">
                        <VisibilityIcon onClick={openBackImageHandler} />
                        {backPreview && (
                          <PreviewDocument
                            imgUrl={backImageURL}
                            closePreview={closePreview}
                          />
                        )}
                        <DownloadIcon onClick={downloadBackHandler} />
                        <DeleteIcon onClick={backDeleteHandler} />
                      </div>
                    </div>
                  )}

                {/* upload selfie */}
                 <div className="flex flex-col gap-4 items-center">
                  <CommonWebcam
                    setImageSrc={(img)=>capture(img)}
                    captureGallary={captureGallary}
                    handleCapture={handleCapture}
                    className={classes.input}
                  />
                  {console.log("new browser view")}

                  {localUserImage && 
                  <img
                      src={userImage}
                      alt="userImage"
                      className="w-[80%] md:h-full md:w-fit md:rounded-lg"
                    />}

                  </div>
                  

                  <div className="w-full  left-0   bottom-4 z-50  mt-8  md:p-4  md:w-[100%] md:mr-auto md:ml-0 md:mt-10 md:bottom-4 md:left-0  md:right-0 ">
                    <CommonButton
                      label="Submit"
                      filled
                      arrow
                      id={"upload_docs_img"}
                      onClickHandler={ovdSubmitHandler}
                    />
                  </div>
                </div>
                {/* </div> */}
                <div className=" mt-3 md:p-0" />
              </div>
            </BottomCard>
          </MsHome>
        </div>
      )}
    </>
  );
};

export default TrUploadDocument;
