import React, { useRef, useState, useEffect } from "react";
import CommonButton from "../CommonButton/CommonButton";

const CommonWebcam = ({setImageSrc, captureGallary, handleCapture, className}) => {

const videoRef = useRef(null);

const canvasRef = useRef(null);



// Start the webcam feed

// useEffect(() => {

//     let sub = true;

// const startWebcam = async () => {

// try {

// const stream = await navigator.mediaDevices.getUserMedia({ video: true });

// if (videoRef.current) {

// videoRef.current.srcObject = stream;

// }

// } catch (err) {

// console.error("Error accessing webcam: ", err);

// }

// };

// if(sub){
//     startWebcam();
// }

// return ()=>{
//     sub = false;
// }

// }, []);

// Capture a photo

const capturePhoto = () => {

const canvas = canvasRef.current;

const video = videoRef.current;

if (canvas && video) {

const context = canvas.getContext("2d");

canvas.width = video.videoWidth;

canvas.height = video.videoHeight;

context.drawImage(video, 0, 0, canvas.width, canvas.height);

const image = canvas.toDataURL("image/jpeg");

setImageSrc(image);
// clickCommonWebcam(image);

}

};

return (

<div style={{ display:"flex" , flexDirection:"column", justifyContent:"center",alignItems:"center", width: "100%" }}>

{/* Video element to display the webcam feed */}

{/* <video ref={videoRef} autoPlay style={{ width:"auto", height:'38vh' }} /> */}

<br />

{/* Capture button */}

<div style={{width:"66%", flexGrow:'1', display:"flex", gap:"1rem" }}>
    {/* <CommonButton
        label="Capture Photo"
        htmlFor="icon-button-file"
        filled
        center
        onClickHandler={capturePhoto}
    /> */}
    <input
        hidden
        accept="image/jpeg,image/png,image/x-eps,image/jpg,image/heic"
        className={className}
        id="icon-button-file-gallary"
        type="file"
        onChange={(e) => handleCapture(e.target)}
    ></input>
    <CommonButton
        label="Upload photo"
        htmlFor="icon-button-file-gallary"
        filled
        center
        onClickHandler={captureGallary}
    /> 
</div>

{/* Canvas to capture the image */}

<canvas ref={canvasRef} style={{ display: "none" }} />

{/* Display captured photo */}

{/* {imageSrc && (

<div>

<h2>Captured Photo</h2>

<img src={imageSrc} alt="Captured" />

</div>

)} */}

</div>

);

};

export default CommonWebcam;