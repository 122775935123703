import React, { Fragment, useState } from "react";
import "./Steppers.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const steps = [
  "Step 1: CKYC details",
  "Step 2: Confirm Name & Address",
  "Step 3: Income, Occupation and Political Exposure",
];
const stepsChannel = [
  "Step 1: CKYC details",
  "Step 2: Confirm Name & Address",
  "Step 3: Kindly capture or upload your photograph",
];
const corporateSteps = [
  "Step 1: CKYC details",
  "Step 2: Company Details",
  "Step 3: kindly upload all document",
];

// const useStyles = makeStyles({   // hdfc color code
//   root: {
//     "& .MuiStepLabel-root .Mui-active": {
//       fill: "rgb(233 78 82)", // circle's number (ACTIVE)
//     },
//     "& .MuiStepLabel-root .Mui-completed": {
//       color: "rgb(233 78 82)", // circle color (COMPLETED)
//     },
//   },
// });
const useStyles = makeStyles({
  //maruti color code
  root: {
    "& .MuiStepLabel-root .Mui-active": {
      fill: (urls) => (urls === true ? "#113569" : "rgb(233, 78, 82)"),
    },
    "& .MuiStepLabel-root .Mui-completed": {
      fill: (urls) => (urls === true ? "#113569" : "rgb(233, 78, 82)"), // circle color (COMPLETED)
    },
  },
});
function Steppers({ children, visibility = true }) {
  // const [activeStep, setActiveStep] = useState(0);

  // const classes = useStyles();
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const urls = channelUrl === "maruti" ? true : false;
  const classes = useStyles(urls);
  const activeStep = useSelector((state) => state.stepperSlice.activeStepper);
  const { kycTypeSelected } = useSelector(
    (state) => state.userDetails.userData
  );
  const { channel } = useSelector(
    (state) => state?.partnerChannelNameSlice?.travelUserDetails || {}
  );

  const kycType = "Corporate";

  return (
    <>
      {visibility && (
        <Box sx={{ width: "100%" }}>
          <div className="mb-2 h-[30px] text-sm">
            {activeStep === 1 ||
              (activeStep === 0 && (
                <p>
                  {kycTypeSelected === kycType ? corporateSteps[0] : steps[0]}
                </p>
              ))}
            {activeStep === 2 ||
              (activeStep === 1 && (
                <p>
                  {kycTypeSelected === kycType ? corporateSteps[1] : steps[1]}
                </p>
              ))}
            {activeStep === 3 ||
              (activeStep === 2 && (
                <p>
                  {kycTypeSelected === kycType ? corporateSteps[2] : channel === "travel" ?
                  stepsChannel[2] : steps[2]}
                </p>
              ))}
            {activeStep === 3 && (
              <p>Step 2: Kindly capture or upload your photograph</p>
            )}
          </div>
          <Stepper
            activeStep={activeStep === 3 ? 2 : activeStep}
            sx={{ marginBottom: "16px" }}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label} classes={classes}>
                  <StepLabel></StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Fragment>
            <div>{children}</div>
          </Fragment>
        </Box>
      )}
    </>
  );
}
export default Steppers;

Steppers.propTypes = {
  visibility: PropTypes.bool,
};
