// const forge = require("node-forge");
// const uuid = require("uuid");
import forge from "node-forge";
import { v4 } from "uuid";
let key = process.env.REACT_APP_ENCRYPTION_KEY;
key = decodeURIComponent(key);

function generateUUID(length = 36) {
  const uuidV = v4().slice(0, length);
  return uuidV;
}

function encrypt(text) {
  let iv = generateUUID(16);
  let cipher = forge.cipher.createCipher("AES-CBC", key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(text, "utf8"));
  cipher.finish();
  let encrypted = cipher.output;
  let d = forge.util.encode64(encrypted.data);
  return `${d}|${iv}`;
}

function decrypt(encryptedText) {
  const splittedText = encryptedText && encryptedText?.split("|");
  const [encData, iv] = [splittedText[0], splittedText[1]];
  let decipher = forge.cipher.createDecipher("AES-CBC", key);
  decipher.start({ iv: iv });
  decipher.update(forge.util.createBuffer(forge.util.decode64(encData)));
  let result = decipher.finish();
  if (result) {
    return decipher.output.data;
  } else {
    throw new Error("Could not decrypt");
  }
}

export { encrypt, decrypt };
// console.time("k");
// let encr = encrypt(JSON.stringify({ name: "ayush" }));
// const decoded = decrypt(encr);
// console.log(decoded);
// console.timeEnd("k");
