import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      color: "black",
    },
    "& .MuiSelect-select": {
      padding: "8px 16px",
    },
  },
});

const CommonDropDown = ({
  options,
  selected = "",
  label,
  setSelected = () => {},
  width,
  selectedValueinRedux = "",
  required = false
}) => {
  const classes = useStyles();
  const defaultValue = options[0];
  const optionList = options.slice();
  optionList.shift();

  return (
    <div className={`${width}`}>
      <FormControl
        sx={{ width: "100%", marginY: 1, fontFamily: "Montserrat" }}
        classes={classes}
      >
        <p className={`lato-family mb-[5px] font-medium ${required && 'required'}`}>{label}</p>
        <Select
          displayEmpty
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
            return selectedValueinRedux;
          }}
          defaultValue={selected}
          className="lato-family"
        >
          <MenuItem disabled value="">
            <em>{defaultValue}</em>
          </MenuItem>
          {optionList?.map((value, index) => (
            <MenuItem
              value={value}
              key={index + Math.random()}
              sx={{ width: "auto", fontFamily: "Montserrat" }}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CommonDropDown;
